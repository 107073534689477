@import "../../index.scss";

.cardContent {
    margin: 3rem auto;
    background: #078ec4;
    padding: 5px;
    box-shadow: 7px 7px 0px #026b95;
    border-radius: 5px;
    width: 80%;
    transition: all 0.2s ease;

    @media screen and (min-width: 720px) {
        width: 300px;
    }

    &:hover {
        transform: translate(-0.5rem, -0.5rem);
        box-shadow: 7px 7px 0px $white;
    }

    img {
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        background: $white;
        @media screen and (min-width: 720px) {
            height: 180px;
        }
    }

    h3 {
        font-size: 20px;
        margin: 1rem auto;
        text-align: center;
        letter-spacing: 2px;

        @media screen and (min-width: 720px) {
            font-weight: 400;
            font-size: 17px;
        }
    }
}

// Scroll animation
@-webkit-keyframes scale-in-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}
@keyframes scale-in-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}
