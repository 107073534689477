@import "../../index.scss";

.homeContent {
    margin: auto 1rem;

    section {
        @media screen and (min-width: 720px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    h1 {
        text-align: center;
        letter-spacing: 1px;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;
        color: white;
        margin: 2rem auto;
        padding: 15px 0;
        display: flex;
        align-items: center;
        border-top: 1px solid;
        border-bottom: 1px solid;

        @media screen and (min-width: 720px) {
            margin-bottom: 10rem;
        }
    }
}

.callToAction {
    background: #edf2f4;
    border: 2px solid #d80032;
    box-shadow: 0px 0px 5px 1px #2b2d42;
    border-radius: 4px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 720px) {
        width: 700px;
    }

    h3 {
        margin: 3rem auto;
        text-align: center;
        letter-spacing: 0.7px;
        font-weight: 400;
        color: $darkBlue;
    }
}

.cardWrapper {
    margin: 5rem auto;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 750px) {
        width: 100%;
        margin: 10rem auto;
        display: flex;
        justify-content: space-between;
    }
}

.animationWrapper {
    @media screen and (min-width: 750px) {
        margin: 1rem;
    }
}
