@import "../../index.scss";

.articleContent {
    margin-bottom: 7rem;
    padding-left: 10px;
    border-left: 1px solid white;

    @media screen and (min-width: 750px) {
        display: flex;
    }

    img {
        width: 100%;
        @media screen and (min-width: 750px) {
            width: 450px;
            object-fit: scale-down;
        }
    }

    h3 {
        font-size: 20px;
        margin: 1rem auto;
        text-align: center;
        letter-spacing: 2px;

        @media screen and (min-width: 750px) {
            text-align: left;
            margin: 0;
        }
    }
    p {
        font-size: 15px;
        text-align: justify;
        letter-spacing: 1px;
        line-height: 20px;

        @media screen and (min-width: 750px) {
            text-align: left;
            line-height: 25px;
            font-size: 20px;
            font-weight: 200;
        }
    }
}

.imgWrapper {
    display: flex;
}

.textWrapper {
    @media screen and (min-width: 750px) {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
