@import "../../index.scss";

.footerContent {
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        margin: 1rem auto;
        color: $darkBlue;
    }

    a {
        font-size: 25px;
        margin: 10px;
    }

    .linkWrapper {
        margin: 1rem auto;
        text-align: center;
        width: 100%;
        @media screen and (min-width: 750px) {
            width: 200px;
            display: flex;
            justify-content: space-between;
        }
    }
}
