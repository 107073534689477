@import "../../index.scss";

.err404 {
    h2 {
        font-size: 80px;
        text-align: center;
        margin: 3rem auto;
    }

    p {
        font-size: 20px;
        text-align: center;
        margin: 3rem auto;
    }
}

.imgContent {
    width: 90%;
    margin: 3rem auto;
    display: flex;
    border: 4px solid #edf2f4;
    border-radius: 4px;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.buttonContent {
    display: flex;
    justify-content: center;
    margin: 3rem auto;
}
