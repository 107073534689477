@import "../../index.scss";

.logoContent {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 15rem;

        @media screen and (max-width: 300px) {
            width: 90%;
        }
    }
}
