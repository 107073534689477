@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");

$white: #edf2f4;
$grey: #8d99ae;
$black: #2b2d42;
$red: #ef233c;
$darkRed: #d80032;
$lightBlue: #078ec4;
$darkBlue: #026b95;

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Roboto";
    color: $white;
}

html {
    height: 100vh;
}

body {
    height: 100%;
    background: url("./medias/images/background-blue.jpg");
}

main {
    margin: 1rem auto;
    max-width: 1200px;
}

button {
    cursor: pointer;
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
    cursor: pointer;
}

path {
    color: $darkBlue;
}
