@import "../../index.scss";

.navBarContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        font-size: 20px;
    }
}

.rotate-down {
    -webkit-animation: rotate-down 0.4s ease-in-out both;
    animation: rotate-down 0.4s ease-in-out both;
}

.rotate-up {
    -webkit-animation: rotate-up 0.4s ease-in-out both;
    animation: rotate-up 0.4s ease-in-out both;
}

@-webkit-keyframes rotate-up {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}
@keyframes rotate-up {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@-webkit-keyframes rotate-down {
    0% {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
@keyframes rotate-down {
    0% {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
