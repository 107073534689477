@import "../../index.scss";

.navigationContent {
    display: flex;
    justify-content: center;

    ul {
        width: 100px;
        @media screen and (min-width: 380px) {
            width: 100%;
            max-width: 450px;
            display: flex;
            justify-content: space-around;
        }

        li {
            margin: 1rem;

            a {
                font-size: 20px;
                display: flex;
                justify-content: center;
                @media screen and (max-width: 380px) {
                    font-size: 15px;
                }

                &:hover {
                    svg {
                        opacity: 0;
                    }

                    &::after {
                        opacity: 1;
                        color: $darkRed;
                    }
                }

                &::after {
                    content: "";
                    opacity: 0;
                    transition: all 0.3s ease-out;
                }

                &:first-child {
                    &::after {
                        content: "HOME";
                        position: absolute;
                    }
                }
            }

            a.services-link {
                &::after {
                    content: "SERVICES";
                }
            }
            a.contact-link {
                &::after {
                    content: "CONTACT";
                }
            }
            a.about-link {
                &::after {
                    content: "A PROPOS";
                }
            }
            // .navActive {
            //     color: red;
            // }
        }
    }
}

.scale-in-ver-top {
    -webkit-animation: scale-in-ver-top 0.4s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: scale-in-ver-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    @media screen and (min-width: 380px) {
        width: 100%;
    }
}

@-webkit-keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
@keyframes scale-in-ver-top {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 100% 0%;
        transform-origin: 100% 0%;
        opacity: 1;
    }
}
