@import "../../index.scss";

.headerContent {
    background: $white;
    z-index: 1;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}
