@import "../../index.scss";

.contactContent {
    margin: auto 1rem;

    p {
        text-align: center;
        margin: 3rem auto;
        @media screen and (min-width: 750px) {
            width: 50%;
        }
    }

    h1 {
        text-align: center;
        letter-spacing: 1px;
        font-weight: 300;
        font-style: italic;
        font-size: 20px;
        color: white;
        margin: 3rem auto;
        padding: 15px 0;
        display: flex;
        align-items: center;
        border-top: 1px solid;
        border-bottom: 1px solid;
        @media screen and (min-width: 750px) {
            margin: 7rem auto;
            width: 50%;
        }
    }

    h3 {
        color: #026b95;
        margin: 1rem auto;
    }

    li {
        color: $lightBlue;
        margin: 0.5rem auto;
    }
}

.openHours {
    margin-bottom: 3rem;
    border: 5px solid #078ec4;
    box-shadow: 5px 5px 0px #026b95;
    background: #edf2f4;
    padding: 1rem;
    @media screen and (min-width: 750px) {
        margin-bottom: 2rem;
    }
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .contactInfo {
        margin-bottom: 3rem;
        border: 5px solid #078ec4;
        box-shadow: 5px 5px 0px #026b95;
        background: #edf2f4;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        @media screen and (min-width: 750px) {
            margin: 1rem auto;
        }

        h3 {
            margin: 1rem 0;
        }

        a {
            color: $lightBlue;
            margin-bottom: 15px;

            span {
                color: $lightBlue;
                &:hover {
                    animation: hoverLink 0.5s ease-in-out both;
                }
            }
        }
    }
}

@keyframes hoverLink {
    100% {
        background: $lightBlue;
        box-shadow: 5px 5px 0 $darkBlue;
        color: $white;
        border-radius: 3px;
        padding: 5px;
    }
}
