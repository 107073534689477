.servicesContent {
    margin: auto 1rem;

    .partnerWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2rem auto;
        border: 5px solid #078ec4;
        box-shadow: 5px 5px 0px #026b95;
        background: #edf2f4;

        @media screen and (min-width: 750px) {
            margin: 7rem auto;
        }

        p {
            color: #026b95;
            margin: 1rem auto;

            @media screen and (min-width: 750px) {
                font-size: 20px;
                letter-spacing: 0.5px;
            }
        }

        .imgPartnerWrapper {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: 750px) {
                width: 100%;
                flex-direction: row;
                justify-content: space-evenly;
            }

            img {
                width: 100%;
                max-width: 300px;
                margin: 0.5rem auto;
                @media screen and (min-width: 750px) {
                    width: 300px;
                }
            }
        }
    }
}
